import React from 'react';
import Styles from './styles.module.css';
import { ReactComponent as UploadSvg } from '../../assets/svg/uploadIcon.svg'
/**
 * Component responsible for handling files via drag or drag&drop.
 *
 * @param getRootProps
 * @param getInputProps
 * @param isDragActive
 * @returns {JSX.Element}
 * @constructor
 */
const EDIDropzone = ({ getRootProps, getInputProps, isDragActive }) => {
    return (
        <div className={Styles.wrapper}>
            <div {...getRootProps()} className={Styles.dropContent} >
                <input {...getInputProps()} />
                <div className={Styles.uploadImage}>
                    <UploadSvg />
                </div>
                <div className={Styles.description}>
                    {
                        isDragActive ?
                            <h6 className={Styles.dropDescription}>EDI Dateien hier ablegen </h6> :
                            <h6 className={Styles.clickDescription}>
                                <span className={Styles.descriptionUnderline}>EDI Datei hochladen</span>
                                <span> (oder per Drag&Drop)</span>
                            </h6>
                    }
                </div>
            </div>
            version: 2024.1811.1
        </div>
    )
};

export default EDIDropzone;